<template>
  <v-snackbar
    :color="snackbar.color"
    :dark="true"
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :top="true"
  >
    <div class="font-size-inc"> 
      <span v-if="snackbar.percentage" style="margin-right: 5px;">
        <v-icon color="white" class="warning-icon">
          mdi-alert-circle
        </v-icon>
      </span>
        {{ snackbar.message }} 
      <span v-if="snackbar.percentage" style="float: right; padding-top: 2px;">{{ snackbar.percentage}}%</span></div>
  </v-snackbar>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "Snackbar",
  watch: {
    snackbar: function (value: any) {
      if (value && value.show) {
        setTimeout(() => {
          this.$store.dispatch("toggleSnackbar", null);
        }, this.snackbar.timeout - 2000);
      }
    },
  },
});
</script>
<style scoped lang="scss">
.font-size-inc {
  font-size: 15px;
}
</style>