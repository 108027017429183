<template>
  <!-- Consumo area -->
  <v-row v-if="this.profiles">

    <v-col class="pb-7">
      <!-- Bill period selector -->
      <!-- End bill period selector -->

      <!-- Bill Notification -->
      <bill-notification class="mt-3"
        v-if="this.profiles.length != 0 && this.historic_data.length == 0 && this.condos_response.selected_role != 'user' && this.billing_periods.length == 0"
        :header="'Introduza a sua primeira fatura!'"
        :text="'Por favor, insira os dados da fatura mais recente de energia do seu condomínio, para que possa consultar todos os custos associados ao consumo de eletricidade efetuado pelos veículos elétricos ligados ao sistema.'"
        :firstNotice="true" />
      <!--  End of Bill Notification -->

      <!-- Actual consumption -->
      <actual-consumption v-if="selected_consumption && condos_response.selected_role === 'user'" />
      <!-- End actual consumption -->

      <!-- Charging session -->
      <div v-if="this.consumption_data.consumptions">
        <div v-if="this.consumption_data.consumptions && this.consumption_data.consumptions[0].sessions.length > 0">
          <charging-session v-if="condos_response.selected_role === 'user' &&
            this.consumption_data.consumptions
            " />
        </div>
      </div>
      <!-- End charging session -->
      <!-- No charging session -->
      <div v-if="this.consumption_data.consumptions && this.consumption_data.consumptions[0].sessions.length == 0">
        <charging-session-no-data v-if="condos_response.selected_role === 'user' &&
          (!this.consumption_data.consumptions)
          " />
      </div>

      <!-- End no charging session -->



      <!-- Actual consumption manager -->
      <actual-consumption-manager v-if="this.condos_response.selected_role === 'manager'" />
      <!-- End actual consumption manager -->
      <!-- Send email -->
      <submit-button class="pb-5" :title="'Enviar relatório de consumos'" :icon="'mdi-send'" :name="'send-user-report'"
        @callEvent="postSendEmailReport" :disabled="this.billing_periods.length == 0 || this.disableButton " />
      <!-- End send email -->
      <!-- Period before consumption -->
      <div v-if="this.billing_periods.length != 0">
        <period-before-consumption v-if="this.consumption_data_prev && this.consumption_data_prev.consumptions &&
          this.condos_response.selected_role === 'user' && this.s_date.send_format == this.billing_periods[0].start_date
          " />
      </div>

      <!-- End period before consumption -->
      <!-- Period before consumption manager -->
      <div v-if="this.billing_periods.length != 0">
        <period-before-consumption-manager v-if="this.consumption_data_prev.consumptions
          && this.condos_response.selected_role === 'manager' && this.s_date.send_format == this.billing_periods[0].start_date
          " />
      </div>
      <!-- End period before consumption manager -->


    </v-col>

    <!-- Success modal -->
    <success-modal :modalName="'send-user-report'" :alt="'send email success'" :icon="'icon-report-sent.png'"
      :header="'Relatório Enviado'"
      :text="'O relatório que solicitou foi enviado em formato Excel para a sua conta de email.'" />
    <!-- End success modal -->
  </v-row>
  <!-- End consumo area -->
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import BillNotification from "@/components/dashboard/bill-notification.vue";
import BillPeriodSelector from "@/components/dashboard/bill-period-selector.vue";
import ActualConsumption from "@/components/dashboard/actual-consumption.vue";
import ChargingSession from "@/components/dashboard/charging-session.vue";
import ChargingSessionNoData from "@/components/dashboard/charging-session-no-data.vue";
import PeriodBeforeConsumption from "@/components/dashboard/period-before-consumption.vue";
import ActualConsumptionManager from "@/components/dashboard/actual-consumption-manager.vue";
import PeriodBeforeConsumptionManager from "@/components/dashboard/period-before-consumption-manager.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import SuccessModal from "@/components/general/success-modal.vue";

export default mixins(General).extend({
  name: "Consumo",
  components: {
    BillPeriodSelector,
    ActualConsumption,
    ChargingSession,
    ChargingSessionNoData,
    PeriodBeforeConsumption,
    ActualConsumptionManager,
    PeriodBeforeConsumptionManager,
    SubmitButton,
    SuccessModal,
    BillNotification,
  },
  data() {
    return {
      dashboard_data: 0 as any,
      disableButton: localStorage.getItem('showConsumptionValuesSnackBar') !== 'false',
    };
  },
  created() {



    this.dashboard_data = this.billing_periods[this.selected_index] ? setInterval(this.getConsumptionData, 120000, this.billing_periods[this.selected_index].start_date, this.billing_periods[this.selected_index].end_date) : null;
  },
  beforeDestroy() {
    clearInterval(this.dashboard_data);
  },
  methods: {
    async postSendEmailReport(btn_name: string): Promise<any> {
      const data = {
        uri: "email_report",
        start_date: this.s_date.send_format,
        end_date: this.e_date.send_format,
        btn_name: btn_name,
      };
      return this.$store.dispatch("sendReport", data).then(async (resp: any) => {
        this.stopLoadingShowModal(btn_name, resp ? true : false);
      });
    },
  },
});
</script>
