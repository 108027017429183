<template>
  <v-row class="mt-4 mb-3 text-center">
    <v-col class="pr-0">
      <v-btn
        depressed
        class="button tab-inactive button-text-2 tab-round-left"
        :class="{
          'tab-active': active_tab_faq === 0,
        }"
        v-on:click="changeActiveTab(0)"
        block
      >
        Suporte Rápido
      </v-btn>
    </v-col>
    <v-col class="pl-0 pr-0">
      <v-btn
        depressed
        class="button tab-inactive button-text-2"
        :class="{
          'tab-active ': active_tab_faq === 2,
        }"
        v-on:click="changeActiveTab(2)"
        block
      >
        Documentos
      </v-btn>
    </v-col>
    <v-col class="pl-0">
      <v-btn
        depressed
        class="button tab-inactive button-text-2 tab-round-right"
        :class="{
          'tab-active ': active_tab_faq === 1,
        }"
        v-on:click="changeActiveTab(1)"
        block
      >
        Contacto
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "FaqMode",
  methods: {
    changeActiveTab(tab_index: number): void {
      this.$emit("callEvent");
      this.$store.commit("updateState", { active_tab_faq: tab_index });
    },
  },
});
</script>

<style scoped lang="scss">
.tab-inactive {
  height: 50px !important;
  background-color: $white-2 !important;

  color: $gray-8;
  width: 100%;
  font-weight: 600;
}

.tab-active {
  padding-top: 5px !important;
  background-color: $white-1 !important;

  border-bottom: 4px solid $blue-1;
  color: $blue-1;
  font-weight: bold;
}

.spacing {
  border-left: 1px solid #dddbdb;
  border-right: 1px solid #dddbdb;
}
</style>
