var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.profiles)?_c('v-row',[_c('v-col',{staticClass:"pb-7"},[(this.profiles.length != 0 && this.historic_data.length == 0 && this.condos_response.selected_role != 'user' && this.billing_periods.length == 0)?_c('bill-notification',{staticClass:"mt-3",attrs:{"header":'Introduza a sua primeira fatura!',"text":'Por favor, insira os dados da fatura mais recente de energia do seu condomínio, para que possa consultar todos os custos associados ao consumo de eletricidade efetuado pelos veículos elétricos ligados ao sistema.',"firstNotice":true}}):_vm._e(),(_vm.selected_consumption && _vm.condos_response.selected_role === 'user')?_c('actual-consumption'):_vm._e(),(this.consumption_data.consumptions)?_c('div',[(this.consumption_data.consumptions && this.consumption_data.consumptions[0].sessions.length > 0)?_c('div',[(_vm.condos_response.selected_role === 'user' &&
          this.consumption_data.consumptions
          )?_c('charging-session'):_vm._e()],1):_vm._e()]):_vm._e(),(this.consumption_data.consumptions && this.consumption_data.consumptions[0].sessions.length == 0)?_c('div',[(_vm.condos_response.selected_role === 'user' &&
        (!this.consumption_data.consumptions)
        )?_c('charging-session-no-data'):_vm._e()],1):_vm._e(),(this.condos_response.selected_role === 'manager')?_c('actual-consumption-manager'):_vm._e(),_c('submit-button',{staticClass:"pb-5",attrs:{"title":'Enviar relatório de consumos',"icon":'mdi-send',"name":'send-user-report',"disabled":this.billing_periods.length == 0 || this.disableButton},on:{"callEvent":_vm.postSendEmailReport}}),(this.billing_periods.length != 0)?_c('div',[(this.consumption_data_prev && this.consumption_data_prev.consumptions &&
        this.condos_response.selected_role === 'user' && this.s_date.send_format == this.billing_periods[0].start_date
        )?_c('period-before-consumption'):_vm._e()],1):_vm._e(),(this.billing_periods.length != 0)?_c('div',[(this.consumption_data_prev.consumptions
        && this.condos_response.selected_role === 'manager' && this.s_date.send_format == this.billing_periods[0].start_date
        )?_c('period-before-consumption-manager'):_vm._e()],1):_vm._e()],1),_c('success-modal',{attrs:{"modalName":'send-user-report',"alt":'send email success',"icon":'icon-report-sent.png',"header":'Relatório Enviado',"text":'O relatório que solicitou foi enviado em formato Excel para a sua conta de email.'}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }