<template>
  <v-row class="rounded-lg pb-8">
    <v-col cols="12" class="text table-header-area rounded-t-lg">
      <v-row class="d-flex text text-5 table-header">
        <v-col cols="7">Períodos</v-col>
        <v-col cols="5" class="d-flex justify-end">
          <v-row class="px-0 py-0" style="max-width: 260px">
            <v-col cols="6" class="pl-0 d-flex justify-center">Consultar</v-col>
            <v-col cols="6" class="pl-4 d-flex justify-center">Editar</v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-card class="col-12 rounded-b-lg">
      <v-col cols="12">
        <v-row
          class="text text-5 d-flex align-center"
          :key="index"
          v-for="(value, index) in this.historic_data"
        >
          <v-col cols="7" class="pl-0">
            De {{ convertDateFormat2(value.bp_start_date) }} a
            {{ convertDateFormat2(value.bp_end_date) }}
          </v-col>
          <!-- <v-col cols="4" class="d-flex justify-end pr-0">
            <button
              :class="index == 0 ? 'filled' : 'not-filled'"
              @click="index == 0 ? openModal() : openModal2(index)"
            >
              {{ index == 0 ? "Editar" : "Ver" }}
            </button></v-col
          > -->
          <v-col cols="5" class="d-flex justify-end">
            <v-row style="max-width: 260px" class="px-0 py-0">
              <v-col cols="6" class="d-flex px-1 justify-center"
                ><img
                  class="clickable"
                  @click="openModal2(index)"
                  src="@/assets/icons/show.svg"
              /></v-col>
              <v-col cols="6" class="d-flex px-1 justify-center">
                <img
                  :class="index == 0 && !disableButton ? 'clickable' : null"
                  @click="index == 0 && !disableButton ? openModal() : null"
                  class="ml-6"
                  :src="
                    index == 0 && !disableButton
                      ? require('@/assets/icons/square-edit.svg')
                      : require('@/assets/icons/square-edit-disabled.svg')
                  "
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <edit-modal :checkSubmit="checkSubmit" :modalName="'edit-modal'" :disableButton="disableButton" @updateButton="updateButton" />
    <tariff-modal
      :header="'Ver Período de Faturação'"
      :historic="true"
      :modalName="'tariff-modal-1'"
      :index="ind"
    />
  </v-row>
</template>


<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import editModal from "../general/edit-modal.vue";
import TariffModal from "../general/tariff-modal.vue";

export default mixins(General).extend({
  components: { editModal, TariffModal },
  name: "HistoricBill",
  props: ["checkSubmit","disableButton"],
  data() {
    return {
      ind: 0,
    };
  },
  methods: {
    openModal2(ind: any): void {
      this.stopLoadingShowModal(
        "tariff-modal-1",
        this.historic_data.length > 0 ? true : false
      );
      this.ind = ind;
    },
    openModal(): void {
      this.stopLoadingShowModal(
        "edit-modal",
        this.historic_data.length > 0 ? true : false
      );
    },
    updateButton(val:any):void{
      this.$emit("updateButton",true);
    }
  },
});
</script>

<style lang="scss" scoped>
.filled {
  min-width: 79.4px;
  height: 30px;
  border-radius: 5px;
  background-color: #47aea5;
  color: #fff;
  font-weight: bold;
}

.not-filled {
  min-width: 79.4px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #47aea5;
  font-weight: bold;
  color: #47aea5;
}
</style>
