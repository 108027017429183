<template>
  <v-row class="d-flex warning-box mx-0 mt-0 mb-0">
    <v-col cols="12" v-if="this.header" style="font-size: 18px" class="font-weight-bold pt-3 pb-1 col col-12">
      {{ this.header }}
    </v-col>
    <v-col cols="12" class="pt-0 pr-5 pb-3 pt-3 content" style="font-size: 15px" v-html="this.text"></v-col>
    <v-col cols="12" class="ttext-center pt-0 pb-4" v-if="this.firstNotice == true"><button class="button"
        @click="goTariff(1)">
        Inserir Fatura
      </button></v-col>
  </v-row>
</template>



<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "BillNotification",
  props: ["firstNotice", "header", "text"],

  data() {
    return {};
  },

  methods: {
    goTariff(tab_index: number): void {
      this.$store.commit("updateState", { active_tab: tab_index });
    },
  },
});
</script>

<style lang="scss" scoped>
.warning-box {
  border: solid 1px #47aea5;
  background-color: rgba(71, 174, 165, 0.05);
  border-radius: 5px;
}

.content ::v-deep {
  .number {
    color: #47aea5;
  }
}

.button {
  width: 166.5px;
  height: 36px;
  border-radius: 5px;
  background-color: #47aea5;
  color: #fff;
  font-weight: bold;
}
</style>
