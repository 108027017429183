<template>
  <v-row v-if="this.active_tab != 4" class="pt-0 pb-4 mt-0">
    <v-col cols="12" :key="index" v-for="(bx_hb, index) in box_hub">
      <v-card class="col-12 rounded-lg">
        <v-row justify="start">
          <v-col cols="12 d-flex justify-start align-center">
            <div class="charger-img-area" v-if="bx_hb">
              <img class="charger-status-img" :src="bx_hb.icon ? getIcon(bx_hb.icon) : null" />
            </div>
            <div class="text-left text text-normal" v-if="bx_hb.state">
              {{ bx_hb.text }}<br />
              <span class="charging-state">
                <v-icon :color="bx_hb.color" class="state-icon">
                  mdi-circle
                </v-icon>
                <span class="pb-1">
                  {{ bx_hb.state }}
                </span>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "ChargingState",
  data() {
    return {
      default: false,
    };
  },
  computed: {
    box_hub() {
      if (this.condos_response.selected_role == 'manager') {
        return this.hub_state
      } else {
        return this.box_state
      }
    }
  },

  mounted() {
    this.updateHubBox();
  },
  methods: {
    async updateHubBox() {

      setInterval(() => {
        this.$store.dispatch("refreshToken").then(() => {
          if (this.condos_response.selected_role == 'user') {
            this.$store.dispatch("getBoxList", { profile_id: localStorage.getItem('selected_profile') }).then((resp) => this.updateChargingState())
          } else {
            this.$store.dispatch("getHubsList", { profile_id: localStorage.getItem('selected_profile') }).then((resp) => this.updateChargingState())

          }
        })

      }, 180000)

    },
  },


});
</script>

<style lang="scss" scoped>
.charger-img-area {
  width: 80px;
  margin-top: 5px;
  margin-left: 5px;
}

.charger-status-img {
  max-width: 25%;
  min-width: 60px;
}

.charging-state {
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 18px;
}

.state-icon {
  font-size: 15px;
  margin-top: -2px;
  margin-right: 2px;
}
</style>
