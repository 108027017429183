

import Vue from "vue";
import moment, { MomentInput } from "moment";
import config from "@/config";
import { mapState } from "vuex";
import { setOptions, bootstrap } from "vue-gtag";

import { Consumption } from "@/store";
import { cloneDeep } from "lodash";
const General = Vue.extend({
  props: [
    "icon",
    "field_key",
    "field_key_faq",
    "condo_key",
    "profile_key",
    "field_faq",
    "field_type",
  ],
  data() {
    return {
      error_message: config.errorMessages,
      selected_index: 0,
      length: 5,
    };
  },
  computed: {
    billing_period(): any {
      return this.$store.getters["billingPeriod"];
    },
    text_field_type: function (): any {
      return this.field_key == "integer" || this.field_key == "float" ? "number" : "text";
    },

    periods: {
      get(): any {
        return this.$store.getters["periodState"]
      }
    },


    googleCookieState: {
      get(): boolean {
        return this.$store.getters["googleCookie"];
      },
      set(newValue: boolean) {
        this.$store.commit("toggleGoogleCookie", newValue);
      },
    },
    faq_text_value: {
      get(): any {
        return this.$store.getters["faqsText"]();
      },
    },


   


    condo_value: {
      get(): any {
        return this.$store.getters["condoData"](this.condo_key)
      },
      set(val: any): any{
        this.$store.dispatch("changeCondosValue", {
          key: this.condo_key,
          value: val,
        })
      }
    },

   

    faq_value: {
      get(): any {
        return this.$store.getters["faqData"](this.field_key_faq);
      },
      set(val: any): any {
        if (this.field_key_faq == "fotos") {
          this.$store.dispatch("changeFaqValue", {
            key: this.field_key_faq,
            value: val,
          });
        } else {
          this.$store.dispatch("changeFaqValue", {
            key: this.field_key_faq,
            value: val,
          });
        }
      },
    },

 profile_value: {
      get(): any {
        return this.$store.getters["profileInfo"](this.profile_key)
      },
      set(val: any): any{
        this.$store.dispatch("changeProfileValue", {
          key: this.profile_key,
          value: val,
        })
      }
    },  

    edit_value: {
      get(): any {
        return this.$store.getters["editData"](this.field_key);
      },


      set(val: any): any {
        if (val != null) {
          if (this.field_type == "integer") {
            val = parseInt(val)
          } else if (this.field_type == "float") {
            val = parseFloat(val)
          }
          if (this.field_type == "string") {
            val = val.toString()
          }
        }
        if (isNaN(val) && this.field_type != "date") {
          val = "";
        }

        

        this.$store.dispatch("changeEditValue", {
          key: this.field_key,
          value: val,
          period: this.period_edit ? this.period_edit : this.periods 
        });
      },
    },


    field_value: {
      get(): any {
        // if(this.field_key != 'bp_start_date' && this.field_key != 'bp_end_date' && this.field_key != 'start_date' && this.field_key != 'end_date'){
        //   return this.$store.getters["contractData"](this.field_key);
        // }
        // else {
        //   const data = this.$store.getters["contractData"](this.field_key);
        //   const formData = new Date(data)
        //   if (moment(formData).format('DD-MM-YYYY') != 'Invalid date'){
        //      return moment(formData, 'YYYY-MM-DD').format('DD-MM-YYYY')

        //   }         
        //   else return ""
        // }
        return this.$store.getters["contractData"](this.field_key);

      },
      set(val: any): any {
        if (val != null) {
          if (this.field_type == "integer") {
            val = parseInt(val)
          } else if (this.field_type == "float") {
            if(val == "-"){
           // No conversion
            }else   {            
              val = parseFloat(val)
            }           
          }
          if (this.field_type == "string") {
            val = val.toString()
          }
        }
        if (isNaN(val) && this.field_type != "date" && val != "-") {
          val = "";
        }
        if(this.field_type == "date"){
          // change to format DD/MM/YYYY
          //val = this.convertDateFormat2(val)
          // const date = new Date(val)
          // val = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        }
        
        this.$store.dispatch("changeFieldValue", { key: this.field_key, value: val, period: this.period ? this.period : this.periods })
      },
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    ...mapState([
      "recovery_token",
      "period_edit",
      "billing_address_condo_length",
      "billing_address_user_length",
      "period",
      "billing_periods",
      "hub_state",
      "box_state",
      "condos_response",
      "profiles",
      "sliced_session",
      "faqs",
      "contract_data",
      "historic_data",
      "active_tab",
      "active_tab_faq",
      "active_tab_profile",
      "user",
      "consumption_data",
      "consumption_data_prev",
      "charging_state",
      "snackbar",
      "s_date",
      "edit_data",
      "e_date",
      "selected_consumption",
      "selected_consumption_before",
      "latest",
      "start",
      "counter",
      "next_enabled",
      "prev_enabled",
      "is_change_password",
      "change_password",
      "faq_data",
      "street",
      "number_tariffs",
    ]),
  },
  methods: {

getEmptyProperties(obj:any) {
    const emptyProps:any = {};
    function checkEmptyProperties(obj: any, path = '') {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                const fullPath = path ? `${path}.${prop}` : prop;
                
                if (obj[prop] == null || obj[prop] === undefined || obj[prop] === '') {
                    emptyProps[fullPath] = obj[prop];
                } else if (typeof obj[prop] === 'object' && Object.keys(obj[prop]).length > 0) {
                    checkEmptyProperties(obj[prop], fullPath);
                }
            }
        }
    }

    checkEmptyProperties(obj);
    return emptyProps;
},


 getStringProperties(obj: any) {
    const stringProps: any = [];

    function extractStringProps(obj: any, parentPath = '') {
        for (const prop in obj) {
            const fullPath = parentPath ? `${parentPath}.${prop}` : prop;
            if (typeof obj[prop] === 'string' || obj[prop] == null) {
                const parts = fullPath.split('.').filter(Boolean);
                stringProps.push(parts[parts.length - 1]);
            } else if (typeof obj[prop] === 'object') {
                extractStringProps(obj[prop], fullPath);
            }
        }
    }

    extractStringProps(obj);
    return stringProps;
},

    handleChangeTab(val: any) {
      this.$store.commit("updateState", { active_tab: val });
    },

    translateRole(profile_type: string): string{  
      if(profile_type == 'Apartment Tenant' || profile_type == 'Apartment Owner'){
        return 'user'
      }else
      {
        return 'manager'
        }
    },
    async getConsumptionData(start_date: any, end_date: any, prev=false): Promise<void> {
      const uri = '/api-condo/consumptions_data';
      let query = ""
      if ((start_date && end_date) || this.condos_response ) {
        query = `?profile_id=${this.condos_response.selected_profile}&start_date=${start_date}&end_date=${end_date}`
      }
      if(prev == false) 
        await this.$store.dispatch("getConsumptionData", { uri: uri, query: query });
      else  
        await this.$store.dispatch("getConsumptionData2", { uri: uri, query: query });

      await this.updateChargingState();
      await this.updateSelectedConsumption(0);
    },
  transformDateProperties(obj:any) {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && moment(obj[key], 'DD-MM-YYYY', true).isValid()) {
      // Convert date from DD-MM-YYYY to YYYY-MM-DD
      obj[key] = moment(obj[key], 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item: any) => this.transformDateProperties(item));
      } else {
       this.transformDateProperties(obj[key]);
      }
    }
  }
},

 transformDateDDMMYYtoDDMMYYYY(date:string) {
    // Split the date into its components
    const parts = date.split('-');

    // Assuming the date format is DD-MM-YY
    const day = parts[0];
    const month = parts[1];
    let year = parts[2];

    // Prepend '20' to the year to convert it to YYYY format
    // This assumes all dates are in the 2000s. Adjust if necessary.
    year = '20' + year;

    // Reconstruct the date in DD-MM-YYYY format
    const transformedDate = `${day}-${month}-${year}`;

    return transformedDate;
},

    getFaqType(type: number): string {
      switch (type) {
        case 0:
          return "Consumos";
        case 1:
          return "Faturação";
        case 2:
          return "Relatórios";
        case 3:
          return "A minha conta";
        case 4:
          return "Outro";
      }
      return "";
    },
    getPlan(plan: any): string {
      if (plan == 0) return "BTN";
      else if (plan == 1) return "BTE";
      else if (plan == 2) return "MT";
      else return "AT";
    },
    async updateChargingState(): Promise<void> {
        let box_state_tmp: any = [];
        let hub_state_tmp: any = [];
        box_state_tmp = [];
        hub_state_tmp = [];
    
        if (this.condos_response.selected_role == "user") {
          if(this.box_state.length == 0){
            box_state_tmp[0] = {...this.box_state[0],
              icon: "icon-faulted.png",
              color: "#f4577d",
              text: "Estado do Carregador",
              state: "Indisponível",
            };
          }else{
          if(this.box_state[0].state != 'Desconhecido'){
                for(let i = 0; i<this.box_state.length; i++){          
           switch (this.box_state[i].detected_state) {
            case "Offline":
              box_state_tmp[i] = {...this.box_state[i],
                icon: "icon-faulted.png",
                color: "#f4577d",
                text: "Estado do Carregador",
                state: "Indisponível",
              };
              break;
            case "Online":
              switch (this.box_state[i].last_event_state) {
                case null:
                  box_state_tmp[i] = {...this.box_state[i],
                  icon: "icon-available.png",
                  color: "#f4577d",
                  text: "Estado do Carregador",
                  state: "Disponível",
                };
                break;                 
              /* case "Finishing":
                box_state_tmp[i] = {...this.box_state[i],
                  icon: "icon-finishing.png",
                  color: "#61A6CC;",
                  text: "Estado do Carregador",
                  state: "A Finalizar",
                };
                break; */
              case "Available":
              case "Preparing":
                box_state_tmp[i] = {...this.box_state[i],
                  icon: "icon-available.png",
                  color: "#94b82d",
                  text: "Estado do Carregador",
                  state: "Disponível",
                };
                break;
               /* case "Reserved":
                box_state_tmp[i] = {...this.box_state[i],
                  icon: "icon-reserved.png",
                  color: "#61A6CC",
                  text: "Estado do Carregador",
                  state: "Reservado",
                };
                break; */
              case "Charging":
              case "Finishing":
                box_state_tmp[i]= {
                  ...this.box_state[i],
                  icon: "icon-charging.png",
                  color: "#47AEA5",
                  text: "Estado do Carregador",
                  state: "Em Carregamento",
                };
                break;
              case "SuspendedEVSE":
                box_state_tmp[i] = {
                  ...this.box_state[i],
                  icon: "suspended.png",
                  color: "#f58c27",
                  text: "Estado do Carregador",
                  state: "Carregamento Suspenso",
                };
                break;
              case "SuspendedEV":
                box_state_tmp[i] = {
                  ...this.box_state[i],
                  icon: "suspended.png",
                  color: "#f58c27",
                  text: "Estado do Carregador",
                  state: "Carregamento Suspenso",
                };
                break;
              /* case "Preparing":
                box_state_tmp[i] = {
                  ...this.box_state[i],
                  icon: "icon-preparation.png",
                  color: "#F2C420;",
                  text: "Estado do Carregador",
                  state: "Em Preparação",
                };
                break; */
              case "Unavailable":
                box_state_tmp[i] = {
                  ...this.box_state[i],
                  icon: "no-power-or-connection-icon.png",
                  color: "#f4577d",
                  text: "Estado do Carregador",
                  state: "Sem Internet ou Energia",
                };
                break;
              case "Faulted":
                box_state_tmp[i] = {
                  ...this.box_state[i],
                  icon: "icon-faulted.png",
                  color: "#EC5959",
                  text: "Estado do Carregador",
                  state: "Indisponível",
                };
                break;
                }            
          }
                   
          }
          }
        
          }
          
        } else {
          for(let i = 0; i<this.hub_state.length; i++){

            
          switch (this.hub_state[i].detected_state) {
            case "Offline":
                hub_state_tmp[i] = {...this.hub_state[i],
                icon: "icon-hub-offline.png",
                color: "#f4577d",
                text: "Estado do Hub",
                state: "Offline",
              };
              break;
                    
              case "Online"://aqui
                hub_state_tmp[i] = {...this.hub_state[i],
                  icon: "icon-hub-online.png",
                  color: "#94b82d",
                  text: "Estado do Hub",
                  state: "Online",
                };              
          }}
        }
     if(this.condos_response.selected_role == 'manager')
        this.$store.commit("updateState", { hub_state: hub_state_tmp });
      else
        this.$store.commit("updateState", { box_state: box_state_tmp.length > 0 ? box_state_tmp : this.box_state });

    },
    async updateSelectedConsumption(
      index = 0,
      is_changed = false
    ): Promise<void> {
      if (
        this.consumption_data &&
        this.consumption_data.consumptions && this.billing_periods.length != 0
      ) {
        const selected_consumption = await this.billing_periods[
          index
        ];
        const selected_consumption_dummy = cloneDeep(selected_consumption);
        const pe =
          selected_consumption_dummy.end_date.split("-");
        const ps =
          selected_consumption_dummy.start_date.split("-");
        selected_consumption_dummy.start_date = {
          year: ps[0],
          month: ps[1],
          day: ps[2],
        };
        selected_consumption_dummy.end_date = {
          year: pe[0],
          month: pe[1],
          day: pe[2],
        };

        this.$store.commit("updateState", { selected_consumption: selected_consumption_dummy });
        const billing_period = selected_consumption;

        let ds = billing_period.start_date

        ds = ds.split("-");
        ds = {
          year: ds[0],
          month: ds[1],
          day: ds[2],
        };
        this.$store.commit("updateState", {
          s_date: {
            display_format: `${ds.day} ${this.translateMonth(ds.month)}`,
            send_format: `${ds.year}-${ds.month}-${ds.day}`,
          },
        });
        let es = billing_period.end_date;
        es = es.split("-");
        es = {
          year: es[0],
          month: es[1],
          day: es[2],
        };
        this.$store.commit("updateState", {
          e_date: {
            display_format: `${es.day} ${this.translateMonth(es.month)}`,
            send_format: `${es.year}-${es.month}-${es.day}`,
          },
        });
        if (
          selected_consumption.sessions &&
          selected_consumption.sessions.length > 5
        ) {
          if (is_changed) {
            this.$store.commit("updateState", { next_enabled: true });
            this.$store.commit("sliceSession", {
              sessions: selected_consumption.sessions,
              start: 0,
              length: 5,
            });
          }
        }
        if (
          this.consumption_data.latest &&
          this.consumption_data.consumptions.length > 1
        ) {
          const selected_consumption_before = await this.consumption_data
            .consumptions[index + 1];
          const selected_consumption_before_temp = cloneDeep(
            selected_consumption_before
          );
          if (selected_consumption_before !== undefined) {
            const temp_start = selected_consumption_before.billing_period.start_date.split("-")
            const temp_end = selected_consumption_before.billing_period.end_date.split("-")
            selected_consumption_before_temp.billing_period.start_date = { year: temp_start[0], month: temp_start[1], day: temp_start[2] }
            selected_consumption_before_temp.billing_period.end_date = { year: temp_end[0], month: temp_end[1], day: temp_end[2] }

          }

          this.$store.commit("updateState", { selected_consumption_before: selected_consumption_before_temp });
        } else {
          this.$store.commit("updateState", { selected_consumption_before: null });
        }
      }
      this.checkButton(0, 0);
    },

    async changeSelectedConsumptionIndex(vals: any): Promise<void> {
      this.selected_index = vals.index;
      this.$store.commit("updateState", { start: 0 });
      this.$store.commit("updateState", { counter: 0 });
      this.$store.commit("updateState", { next_enabled: false });
      this.$store.commit("updateState", { prev_enabled: false });
      await this.updateSelectedConsumption(this.selected_index, true);
    },
    getIcon(icon = null): string {
      const ic = icon ? icon : this.icon
      return require(`@/assets/icons/${ic}`);
    },
    togglePrivacyCookie(status: boolean, gstatus: boolean) {
      this.$store.commit("togglePrivacyCookie", status);
      this.$store.commit("toggleGoogleCookie", gstatus);
      this.updateGoogleCookie();
    },
    updateGoogleCookie(): void {
      setOptions({
        enabled: this.googleCookieState,
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
      });
      if (this.googleCookieState === true) {
        bootstrap();
      }
    },
    changeBillingValue(value: any): void {
      this.$store.commit("updateBillingPeriod", value);
    },
    changeFieldValue(key: string, value: any, period: any): void {
      const arr: any = {};
      arr[key] = value;

      this.$store.commit("updateContractInfo", arr, period);
    },
    isEmailValid(email: any): any {
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    translateMonthName(month_string: string): any {
      const month = parseInt(month_string)
      let val = "";
      switch (month) {
        case 1:
          val = "JAN";
          break;
        case 2:
          val = "FEV";
          break;
        case 3:
          val = "MAR";
          break;
        case 4:
          val = "ABR";
          break;
        case 5:
          val = "MAI";
          break;
        case 6:
          val = "JUN";
          break;
        case 7:
          val = "JUL";
          break;
        case 8:
          val = "AGO";
          break;
        case 9:
          val = "SET";
          break;
        case 10:
          val = "OUT";
          break;
        case 11:
          val = "NOV";
          break;
        case 12:
          val = "DEZ";
          break;
      }
      return val;
    },
    translateMonth(month_string: string): any {
      const month = parseInt(month_string)
      let val = "";
      switch (month) {
        case 1:
          val = "Janeiro";
          break;
        case 2:
          val = "Fevereiro";
          break;
        case 3:
          val = "Março";
          break;
        case 4:
          val = "Abril";
          break;
        case 5:
          val = "Maio";
          break;
        case 6:
          val = "Junho";
          break;
        case 7:
          val = "Julho";
          break;
        case 8:
          val = "Agosto";
          break;
        case 9:
          val = "Setembro";
          break;
        case 10:
          val = "Outubro";
          break;
        case 11:
          val = "Novembro";
          break;
        case 12:
          val = "Dezembro";
          break;
      }
      return val;
    },
    closeModal(mName: string) {
      this.$store.commit("toggleModalState", {
        name: mName,
        status: false,
      });
    },
    getTransMonth(date: any) {
      if (date && date.month) {
        return this.translateMonth(date.month);
      }
    },
    getTransMonth2(date: any) {   
        return this.translateMonth(date);      
    },

    getTransformMonthName(date: any) {
      if (date && date.month) {
        return this.translateMonthName(date.month);
      }
    },

    getTransformMonthName2(date: any){
      return this.translateMonthName(date)
    },
    getDay(date: any) {
      if (date && date.day) {
        return date.day;
      }
    },
    getMonth(date: any) {
      if (date && date.month) {
        return date.month;
      }
    },

   
    convertDateFormat2(dateStr: any) {
      // Split the input string into an array ['YYYY', 'MM', 'DD']
      const date = new Date(dateStr)
      const date_return = moment(date).format('DD-MM-YYYY');
      return date_return;
    },


    parseDate(dateString: string) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Subtract 1 since months are 0-indexed
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    } else {
        throw new Error("Invalid date format. Please use DD/MM/YYYY.");
    }
},

    

    convertDateFormat(dateStr: any) {
      // Split the input string into an array ['YYYY', 'MM', 'DD']
      const parts = dateStr.split('-');

      // Reorder the array elements to ['DD', 'MM', 'YYYY']
      let newFormat: any = [parts[2], parts[1], parts[0]];
      const newFormat_dmp: any = newFormat.join("/");      
      try{
          newFormat = this.parseDate(newFormat_dmp);
          return newFormat;

      } catch(error: any){
            console.error("Error occurred:", error.message);
      }
      // Join the array elements back into a string 'DD-MM-YYYY'
    },
    getYear(date: any) {
      if (date && date.year) {
        return date.year;
      }
    },
    stopLoadingShowModal(btn_name: string, show_success: boolean) {
      this.$store.commit("toggleButtonState", {
        name: btn_name,
        status: false,
      });
      if (show_success) {
        this.$store.commit("toggleModalState", {
          name: btn_name,
          status: true,
        });
      }
    },
    async setSelectedPeriod(start_date: any, end_date: any): Promise<void> {
      this.closeModal("date_modal");
      const sd = `${start_date.date()} ${this.translateMonth(
        (start_date.month() + 1).toString()
      )}`;
      const ssd = `${start_date.format("YYYY")}-${start_date.format("MM")}-${start_date.format("DD")}`
      const ed = `${end_date.date()} ${this.translateMonth(
        (end_date.month() + 1).toString()
      )}`;
      const eed = `${end_date.format("YYYY")}-${end_date.format("MM")}-${end_date.format("DD")}`
      await this.getConsumptionData(ssd, eed).then((data) => {
        this.$store.commit("updateState", { s_date: { display_format: sd, send_format: ssd } });
        this.$store.commit("updateState", { e_date: { display_format: ed, send_format: eed } });
      })
    },
    setBillingPeriod(period_string = "start") {
      if (this.billing_periods.length > 0 ) {
        const val =
          period_string == "start"
            ? this.billing_periods[this.selected_index]
              .start_date
            : this.billing_periods[this.selected_index]
              .end_date;
        let val_dummy = val.split("-")
        val_dummy = {
          year: val_dummy[0],
          month: val_dummy[1],
          day: val_dummy[2]

        }

        const n_val = moment(`${val_dummy.year}-${val_dummy.month}-${val_dummy.day}`);

        return n_val;
      } else {
        return null;
      }
    },
    splitChargingSessions(n_start = 0) {
      this.$store.commit("sliceSession", {
        sessions: this.consumption_data.length >0 ? this.consumption_data.consumptions[0].sessions : null,
        start: n_start,
        length: this.length,
      });
    },
    
    // Method implementation to handle pagination and toggling of next/previous buttons

    checkButton(n_start: number, n_counter: number) {
      this.$store.commit("updateState", { start: n_start });
      this.$store.commit("updateState", { counter: n_counter });
      this.splitChargingSessions(n_start);
      if ( this.consumption_data.length >0 ? this.consumption_data.consumptions[0].sessions : null) {
        if ((n_counter + this.length) > this.consumption_data.consumptions[0].sessions.length) {
          this.$store.commit("updateState", { next_enabled: false });
        } else {
          this.$store.commit("updateState", { next_enabled: true });
        }
        if (n_counter == 0) {
          this.$store.commit("updateState", { prev_enabled: false });
        } else {
          this.$store.commit("updateState", { prev_enabled: true });
        }
      }
    },
    callMethod(e = 'default_value') {
      this.$emit("checkMethod");
    }
  }
});

export { General };

