var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"mt-4 mb-3 text-center"},[_c('v-col',{staticClass:"pr-0"},[_c('v-btn',{staticClass:"button tab-inactive button-text-2 tab-round-left",class:{
        'tab-active': _vm.active_tab_faq === 0,
      },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(0)}}},[_vm._v(" Suporte Rápido ")])],1),_c('v-col',{staticClass:"pl-0 pr-0"},[_c('v-btn',{staticClass:"button tab-inactive button-text-2",class:{
        'tab-active ': _vm.active_tab_faq === 2,
      },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(2)}}},[_vm._v(" Documentos ")])],1),_c('v-col',{staticClass:"pl-0"},[_c('v-btn',{staticClass:"button tab-inactive button-text-2 tab-round-right",class:{
        'tab-active ': _vm.active_tab_faq === 1,
      },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(1)}}},[_vm._v(" Contacto ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }