var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"px-0"},[_c('v-col',{class:['text text-5  pb-0 pt-0', _vm.bold ? 'normal-label' : ''],attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticStyle:{"color":"rgba(46, 51, 53, 0.3)","font-weight":"400"}},[_vm._v(" (Opcional)")])]),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"cols":"12"}},[_c('div',{class:[
        'upload-area',
        _vm.dragging ? 'upload-area-dragging' : '',
        this.faq_data.document_upload.length == 0 ? 'align-center' : '',
        this.disabled == false ? 'disabled-bg' : null,
      ],on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop,"dragenter":_vm.dragEnter,"dragleave":_vm.dragLeave}},[(this.faq_data.document_upload.length != 0)?_c('div',{staticClass:"upload-area-after"},[[_c('div',{staticClass:"text-14 px-2 pt-2"},[_c('v-row',[_c('v-col',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',{staticClass:"mb-3 d-flex"},[_c('v-col',{staticClass:"mx-0 py-0 px-0 my-0"},[_c('v-row',{staticClass:"mx-0 py-0 px-0 my-0 grid-list-xl"},_vm._l((this.names),function(f,k){return _c('v-col',{key:k,staticClass:"px-2 mb-1 py-0 my-0 parent d-flex justify-center",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{staticClass:"mx-0 py-0 px-0 my-0 image-container rounded-lg d-flex justify-center"},[_c('v-col',{staticClass:"py-0 pl-0 d-flex align-center",attrs:{"cols":"2"}},[_c('img',{staticClass:"image-inside",attrs:{"src":f.url}})]),_c('v-col',{staticClass:"d-flex pl-2 font-weight-bold align-center",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.names[k].name))]),_c('v-col',{staticClass:"d-flex justify-end align-center clickable",attrs:{"cols":"2"},on:{"click":function($event){return _vm.handleDelete(k)}}},[_c('img',{staticStyle:{"height":"36px"},attrs:{"src":require("@/assets/icons/bin.svg")}})])],1)],1)}),1)],1)],1),_c('div')],1)],1)],1)],1)],_c('div',{staticClass:"input-area"},[_c('div',[(this.faq_data.document_upload.length != 0)?_c('v-row',{staticClass:"upload-area-before text-14 mb-3 d-flex justify-center"},[_c('span',{staticClass:"upload-area-link pr-1",style:({
                  color: this.disabled == false ? '#9A9A9A' : '#47aea5',
                })},[_vm._v("Clique aqui")]),_c('span',[_vm._v(" ou arraste as suas fotografias")])]):_vm._e()],1),_c('input',{ref:'fup',attrs:{"disabled":!_vm.disabled,"type":"file"},on:{"change":_vm.handleFileUpload}})])],2):_vm._e(),_c('div',{staticClass:"upload-area-msg mt-9",style:(this.faq_data.document_upload.length != 0 ? 'top: 127px' : null),on:{"drag":_vm.handleFileUpload}},[(this.faq_data.document_upload.length == 0)?_c('div',[_c('img',{style:({
              filter:
                this.disabled == false
                  ? 'invert(57%) sepia(46%) saturate(9%) hue-rotate(95deg) brightness(99%) contrast(87%)'
                  : '',
            }),attrs:{"src":require("@/assets/icons/Layer_1.svg")}}),_c('input',{ref:'fup',staticClass:"clickable",attrs:{"disabled":!_vm.disabled,"type":"file"},on:{"change":_vm.handleFileUpload}})]):_vm._e(),(this.faq_data.document_upload.length == 0)?_c('v-row',{staticClass:"clickable upload-area-before text-14 pt-3"},[_c('span',{staticClass:"upload-area-link",style:({ color: this.disabled == false ? '#9A9A9A' : '#47aea5' })},[_vm._v("Clique aqui")]),_c('span',{style:({ color: this.disabled == false ? '#9A9A9A' : 'black' })},[_vm._v(" ou arraste as suas fotografias")])]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }